<template>

  <NuxtLink
    v-if="tag == 'NuxtLink'"    
    :to="to"
    :title="title"
    :class="'btn ' + type + (disabled ? ' disabled' : '') + (isLoading ? ' cloading disabled' : '') + (cnt ? ' btn-cnt' : '') + (!name ? ' icon-only' : '')" 
    :target="target"
    @click="click"
    >    

    <div class="name">
      <i v-if="ico" :class="ico"></i>
      {{name}}        
    </div>
    
    <span v-if="cnt" class="cnt">
      {{cnt}}
    </span>     

    <slot></slot>

  </NuxtLink>
  
  <component 
    v-if="tag != 'NuxtLink'"
    :is="tag"   
    :title="title"     
    :class="'btn ' + type + (disabled ? ' disabled' : '') + (isLoading ? ' cloading disabled' : '') + (cnt ? ' btn-cnt' : '') + (!name ? ' icon-only' : '')" 
    :data-test="locator"
    @click="click"
    >    
    <div class="name">
      <i v-if="ico" :class="ico"></i>
      {{name}}      
    </div>
    <span v-if="cnt" class="cnt">
      {{cnt}}
    </span>    
    <slot></slot>
  </component>
  
</template>

<script setup lang="ts">

interface PageProps {
  tag?: string,
  name?: string,
  target?: string | null,
  cnt?: number,
  type?: string,
  title?: string,
  ico?: string,
  to?: string | null,
  disabled?: boolean,
  isLoading?: boolean,
  locator?: string  
}

const props = withDefaults(defineProps<PageProps>(), {
  tag: 'div',
  name: '',
  target: null,
  cnt: 0,
  type: '',
  title: '',
  ico: '',
  to: null,
  disabled: false,
  isLoading: false,
  locator: ''
})
 
// const props = defineProps({
//   tag: {
//     type: String,
//     required: false,
//     default: 'div',
//     // validator: (value) => {
//     //     return config.defaultLinkTags.indexOf(value) >= 0
//     // }
//   },
//   name: {
//     type: String, 
//     default: '',
//   },
//   target: {
//     type: String, 
//     default: null,
//   },
//   cnt: {
//     type: Number,    
//   },
//   type: {
//     type: String, 
//     default: '',
//   },
//   title: {
//     type: String, 
//     default: null,
//   },
//   ico: {
//     type: String,      
//   }, 
//   to: {
//     type: String, 
//   }, 
//   disabled: {
//     type: Boolean, 
//     default: false,
//   },
//   isLoading: {
//     type: Boolean, 
//     default: false,
//   },
//   locator: {
//     type: String,
//     default: ''
//   }, 
// });

const emits = defineEmits(['click', 'click.prevent']);

const computedTag = computed(() => {
  if (!props.tag) {
    return 'div'
  }
  resolveComponent(props.tag)
  return props.tag
})

const click = (e) => {
  emits('click', e)
}
 

// export default {  
//   props: {    
//     tag: {
//       type: String,
//       required: false,
//       default: 'div',
//       // validator: (value) => {
//       //     return config.defaultLinkTags.indexOf(value) >= 0
//       // }
//     },
//     name: {
//       type: String, 
//       default: '',
//     },
//     cnt: {
//       type: Number,    
//     },
//     type: {
//       type: String, 
//       default: '',
//     },
//     ico: {
//       type: String,      
//     }, 
//     to: {
//       type: String, 
//     }, 
//     disabled: {
//       type: Boolean, 
//       default: false,
//     },
//     isLoading: {
//       type: Boolean, 
//       default: false,
//     },
//   }, 
//   data () {
//     return {    
//     }
//   },
//   created() {    
    
//   },
//   computed: {
//     computedTag() {
//       if (!this.tag) {
//         return 'div'
//       }
//       resolveComponent(this.tag)
//       return this.tag
//     },
//   },
//   methods: {    
//     click(){            
//       this.$emit('click')      
//     }, 
//   }
// }
</script>

<style scoped>


.sort{
  position: relative;
  display: flex;
  align-content: center;
}
.sort.right{
  justify-content: flex-end;
}
.sort .hnt{

}
.sort .ched{  
  font-weight: bold;
  margin-left: 5px;
  position: relative;
  display: flex;
  align-content: center;
  cursor: pointer;
}
.sort .opts{
  display: none;
  position: absolute;
  right: 0;
  top: 20px;
  background-color: white;
  z-index: 10;
  padding: 0;
  border-radius: 3px;
  box-shadow: 0px 2px 3px 0px rgb(34 36 38 / 15%);
  border: 1px solid rgba(34, 36, 38, 0.15);
  min-width: 140px;

}
.sort.showed .opts{
  display: initial;
}

.sort .opts .opt{
  padding: 5px 15px;
  cursor: pointer;
  text-align: center;
}
.sort .opts .opt:hover{
  background-color: rgb(236, 236, 236);
} 

.disabled{
  opacity: 0.5;
  cursor: not-allowed;
}

</style>
